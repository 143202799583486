import { useRef, useEffect } from 'react';
import './App.css';

import { Router_Provider } from './setUp/Router/router'
import { useLocation } from 'react-router-dom';
const ScrollToTop = () => {
  const { pathname } = useLocation();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);

  return null;
};
function App() {
  const divRef = useRef(null);

  function handleScroll() {
    const sections = divRef.current.querySelectorAll("section");
    const body = document.querySelector("body");
    for (const sec of sections) {
      let att = sec.getAttribute("data-theme");
      if (att == "dark" && sec.getBoundingClientRect().top <= 0 && sec.clientHeight >= 0) {
        body.classList.add("header-dark");
      } else if (att == "light" && sec.getBoundingClientRect().top <= 0 && sec.clientHeight >= 0) {
        body.classList.remove("header-dark");
      }
    }
  }
  useEffect(() => {
    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);
  return (
    <div ref={divRef}>
      {Router_Provider}
    </div>
  );
}

export default App;
