import axios from 'axios';


const API = axios.create({
  baseURL: 'https://centralservices.procoor.com/api/WebsiteController/',
  //timeout: 5000,
  headers: {
    // 'Content-Type': '*/*',
    // 'Authorization': `Bearer ${state.token.replace(/"/g, '')}`,
    'Content-type': 'application/json',
    Accept: '*/*',
    "Access-Control-Allow-Credentials": true,
    "Access-Control-Allow-Origin": 'https://centralservices.procoor.com/api/WebsiteController/',
  },
});
export const APISUBMIT = axios.create({
  baseURL: 'https://centralservices.procoor.com/api/WebsiteController/',
  //timeout: 5000,
  headers: {
    // 'Content-Type': '*/*',
    // 'Authorization': `Bearer ${state.token.replace(/"/g, '')}`,
    'Content-Type': 'multipart/form-data',
    Accept: '*/*',
    "Access-Control-Allow-Credentials": true,
    "Access-Control-Allow-Origin": 'https://centralservices.procoor.com/api/WebsiteController/',
  },
});



export default API;