import React, { useState,useEffect } from "react";
import API from "Server/API";
import { Link } from "react-router-dom";

const Footer = () => {
  const [successMsg, setSuccessMsg] = useState("");
  const [values, setValues] = useState({
    email: '',
  });
  const handleChange = e => {
    const { name, value } = e.target;
    setValues({
      ...values,
      [name]: value
    });
  };
  const handleSubmit = async (e) => {
    e.preventDefault();
    var raw = ({
      "email": values.email
    });
    API.post('AddNewsLetter', raw).then(res => {
      setValues({
        email: '',
      });
      setSuccessMsg("send email is successful.");
    }).catch(err => {
    });
    setTimeout(() => {
      setSuccessMsg("");
    }, 3000)
  };
  return (
    <footer data-theme="dark">
      <div className="container">
        <div className="row">
          <div className="col-md-4 order-0">
            <div className="footer-logo">
              <div>
                <Link to={"/"} target="_blank">
                  <img src="assets/images/footerLogo.svg" className="img-fluid" alt="procoor" />
                </Link>
                <p className="info">
                  Procoor is a construction management system specially designed by engineering and
                  construction
                  professionals for contractors, consultants &amp; owners. Procoor revolutionizes the way
                  construction and
                  engineering professionals coordinate their work.
                </p>
              </div>
              <div className="Newsletter">
                <h4 className="title">Newsletter</h4>
                <span className="sub__title">Get updated with latest Features &amp; news </span>
                <form className="  g-3 needs-validation" onSubmit={handleSubmit}>
                  <div className="form-group newsletter-form">
                    <input type="email" className="form-control" placeholder="Enter your email here" name="email" value={values.email} required onChange={handleChange} />
                    <button type="submit" className="send"><img src="assets/images/icons/send.svg" alt="" /></button>
                  </div>
                  {successMsg && <p className="success-msg">{successMsg}</p>}
                </form>
              </div>
            </div>
          </div>
          <div className="col-md-4 col-sm-4 col-6 order-1 justify-content-center d-flex">
            <div className="footer-links">
              <h4 className="title">Procoor</h4>
              <ul className="links">
                <li><Link to={"/"} className="link">Home</Link></li>
                <li><Link to={"/features"} className="link">Features</Link></li>
                <li className="templat-none"><Link to={"#"} className="link">Take a tour</Link></li>
                <li className="templat-none"><Link to={"#"} className="link">Watch live video</Link></li>
                <li className="templat-none"><Link to={"#"} className="link ">Pricing</Link></li>
                <li><Link to={"/enterprise"} className="link">Enterprise</Link></li>
                <li className="line"><span /></li>
                <li className="templat-none"><Link to={"#"} className="link">Careers</Link></li>
                <li className="templat-none"><Link to={"#"} className="link">About</Link></li>
                <li><Link to={"/contact"} className="link">Contact us</Link></li>
                <li><Link to={"/terms"} className="link">terms</Link></li>
              </ul>
            </div>
          </div>
          <div className="col-md-4 col-sm-4 col-6 order-3 order-sm-2 justify-content-center d-flex">
            <div className="footer-links">
              <h4 className="title">Products</h4>
              <ul className="links">
                <li><Link to={"/pm"} className="link">Project management</Link></li>
                <li><Link to={"/contract"} className="link">Contract management</Link></li>
                <li><Link to={"/procurement"} className="link">Procurement</Link></li>
                <li><Link to={"/cost-management"} className="link">Cost management</Link></li>
                <li><Link to={"/estimation"} className="link">Estimation management</Link></li>
                <li><Link to={"/quality"} className="link">Quality control</Link></li>
                <li><Link to={"/equipments"} className="link">Equipment management</Link></li>
                <li><Link to={"/accounting"} className="link">Accounting</Link></li>
                <li><Link to={"/hr"} className="link">Human resources</Link></li>
                <li className="templat-none"><Link to={"#"} className="link">Facility management</Link></li>
              </ul>
            </div>
          </div>
          <div className="col-md-3 col-sm-4 col-6 order-2 order-sm-3 templat-none">
            <div className="footer-links">
              <h4 className="title">Learn</h4>
              <ul className="links">
                <li><Link to={"#"} className="link">Blog</Link></li>
                <li><Link to={"#"} className="link">Videos</Link></li>
                <li><Link to={"#"} className="link">Guides</Link></li>
                <li><Link to={"#"} className="link">Procoor academy</Link></li>
                <li><Link to={"#"} className="link">Webinars</Link></li>
                <li className="line"><span /></li>
                <li>
                  <h3 className="sub__Title">Support</h3>
                </li>
                <li><Link to={"#"} className="link">Help center</Link></li>
                <li><Link to={"#"} className="link">Professional services</Link></li>
                <li><Link to={"#"} className="link">Interactive training</Link></li>
              </ul>
            </div>
          </div>
        </div>
        <div className="row bottom-footer justify-content-center">
          <div className="col-lg-3 col-md-2  col-sm-6 templat-none">
            <div className="dropdown language">
              <button className="btn dropdown-toggle`" type="button" id="language" data-bs-toggle="dropdown" aria-expanded="false">
                <img src="assets/images/icons/langFooterEarth.svg" className="language__earth" alt="earth" />
                <span className="language__title">English</span>
                <img src="assets/images/icons/langChevronDown.svg" className="language__arrow" alt="arrow down" />
              </button>
              <ul className="dropdown-menu" aria-labelledby="language">
                <li><Link className="dropdown-item disabled">Language</Link></li>
                <li>
                  <Link className="dropdown-item" to={"#"}>
                    <span>Arabic</span>
                    <img src="assets/images/icons/correctBlueArrow.svg" alt="selected" />
                  </Link>
                </li>
                <li><Link className="dropdown-item" to={"#"}>English</Link></li>
                <li><Link className="dropdown-item" to={"#"}>French</Link></li>
              </ul>
            </div>
          </div>
          <div className="col-lg-3 col-md-3 col-sm-6">
            <ul className="list-unstyled social justify-content-center">
              <li>
                <Link to={"https://www.facebook.com/ProcoorInternational/"} target="_blank" className="facebook">
                  <img src="assets/images/icons/fcbk.svg" alt="facebook" />
                </Link>
              </li>
              <li>
                <Link to={"https://eg.linkedin.com/company/procoor"} target="_blank" className="linkedin">
                <img src="assets/images/icons/lkd-in.svg" alt="facebook" />
                </Link>
              </li>
              <li>
                <Link to={"https://www.instagram.com/procoorinternational/"} target="_blank" className="instagram">
                <img src="assets/images/icons/instagram.svg" alt="instagram" />
                </Link>
              </li>
            </ul>
          </div>
          <div className="col-lg-3 col-md-4 col-12 templat-none">
            <ul className="downloadApp">
              <li>
                <Link to={"#"}><img src="assets/images/icons/appStore.svg" className="img-fluid" alt="app Store" /></Link>
              </li>
              <li>
                <Link to={"#"}><img src="assets/images/icons/appStore.svg" className="img-fluid" alt="app Store" /></Link>
              </li>
            </ul>
          </div>
          <div className="col-lg-3 col-md-3  col-12 templat-none">
            <p className="site-links">
              <Link to={"#"}>Privacy &amp; Terms</Link>
              <Link to={"#"}>Sitemap</Link>
            </p>
          </div>
          <div className="col-12 order-4">
            <p className="site-links copyRights"> © copyright - 2022 | <Link to={"#"} /> Procoor  all rights
              reserved </p>
          </div>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
