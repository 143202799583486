import {
  createBrowserRouter,
  RouterProvider,
  Route,
  createRoutesFromElements
} from "react-router-dom";
import { lazy, Suspense } from "react";
import Layout from "common/Layout"

const Home = lazy(() => import('pages/home/Home'));
const HR = lazy(() => import('pages/hr/HR'));
const Pm = lazy(() => import('pages/pm/Pm'));
const Plans = lazy(() => import('pages/plans/Plans'));
const Procurement = lazy(() => import('pages/procurement/Procurement'));
const Quality = lazy(() => import('pages/quality/Quality'));
const Solutions = lazy(() => import('pages/solution/Solution'));
const Accounting = lazy(() => import('pages/accounting/Accounting'));
const Company = lazy(() => import('pages/company/Company'));
const Contact = lazy(() => import('pages/contact/Contact'));
const Contract = lazy(() => import('pages/contract/contract'));
const CostManagement = lazy(() => import("pages/costManagement/CostManagement"));
const Customers = lazy(() => import("pages/customers/Customers"));
const Enterprise = lazy(() => import('pages/enterprise/Enterprise'));
const Equipments = lazy(() => import('pages/equipments/Equipments'));
const ConstructionManagement = lazy(() => import('pages/ConstructionManagement/ConstructionManagement'));
const Solution = lazy(() => import('pages/solution/Solution'));
const Estimation = lazy(() => import("pages/estimation/Estimation"))
const Features = lazy(() => import("pages/features/Features"))
const FM = lazy(() => import("pages/fm/FM"));
const CRM = lazy(() => import("pages/Crm/CRM"));
const Terms = lazy(() => import("pages/terms/Terms"));
const Teams = lazy(() => import("pages/Team/Teams"));
const EmployeeCard = lazy(() => import("pages/Team/EmployeeCard/EmployeeCard"));
const Login = lazy(() => import("pages/Login/Login"));
const ForgotPassword = lazy(() => import("pages/ForgotPassword/ForgotPassword"));
const CreateAccount = lazy(() => import("pages/Create_account/CreateAccount"));
const Chekout = lazy(() => import("pages/plans/components/CheckoutPlan"));
const ThankPage = lazy(() => import("pages/plans/components/ThankPage"));
const ThankContact = lazy(() => import("pages/plans/components/ThankPageContact"));
const Error = lazy(() => import("pages/plans/components/erroPage"));


const router = createBrowserRouter(
  createRoutesFromElements(
    <>
      <Route element={<Layout />}>
        <Route index element={<Home />} />
        <Route path="/hr" element={<HR />} />
        <Route path="/pm" element={<Pm />} />
        <Route path="/plans" element={<Plans />} />
        <Route path="/procurement" element={<Procurement />} />
        <Route path="/quality" element={<Quality />} />
        <Route path="/solutions" element={<Solutions />} />
        <Route path="/accounting" element={<Accounting />} />
        <Route path="/company" element={<Company />} />
        <Route path="/contract" element={<Contract />} />
        <Route path="/cost-management" element={<CostManagement />} />
        <Route path="/solution" element={<Solution />} />
        <Route path="/equipments" element={<Equipments />} />
        <Route path="/estimation" element={<Estimation />} />
        <Route path="/Construction-Management" element={<ConstructionManagement />} />
        <Route path="/features" element={<Features />} />
        <Route path="/fm" element={<FM />} />
        <Route path="/crm" element={<CRM />} />
        <Route path="/customers" element={<Customers />} />
        <Route path="/enterprise" element={<Enterprise />} />
        <Route path="/chekout" element={<Chekout />} />
        <Route path="/Terms" element={<Terms />} />
        <Route path="/OurTeam" element={<Teams />} />
        <Route path="/EmployeeCard" element={<EmployeeCard />} />

      </Route>

      <Route path="/login" element={<Login />} />
      <Route path="/contact" element={<Contact />} />
      <Route path="/thankyou" element={<ThankPage />} />
      <Route path="/ThankContact" element={<ThankContact />} />
      <Route path="/error" element={<Error />} />
      <Route path="/CreateAccount" element={<CreateAccount />} />
      <Route path="/ForgotPassword?" element={<ForgotPassword />} />
  

    </>
  ),
  {
    onRouteChange() {
      window.scrollTo(0, 0);
    }
  }
);

export const Router_Provider = <Suspense fallback={<h1></h1>}>
  <RouterProvider router={router} />
</Suspense>
